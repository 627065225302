import { useLocalStorage } from 'usehooks-ts';

export function usePlayerCount() {
  const [playerCount, setPlayerCount] = useLocalStorage<number>('playerCount', 4);

  const increment = () => {
    setPlayerCount(prev => Math.min(prev + 1, 12));
  };

  const decrement = () => {
    setPlayerCount(prev => Math.max(prev - 1, 1));
  };

  return {
    playerCount,
    setPlayerCount,
    increment,
    decrement
  };
} 