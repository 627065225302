import React from "react";
import GamerMasterView from "./game-master/GamerMasterView";
import { PlayerView } from "./player/PlayerView";
import {
  createBrowserRouter,
} from "react-router-dom";
import { DevPage } from "./dev/DevPage";
import AuthCallbackView from "./game-master/auth/AuthCallbackView";
import { ReviewPage } from "./admin/ReviewPage";
import { PrivateAdversariesPage } from "./admin/PrivateAdversariesPage";
import { SharedEncounterView } from "./player/SharedEncounterView";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <GamerMasterView/>,
  },
  {
    path: "/player/:gameId",
    element: <PlayerView />,
  },
  {
    path: "/encounter",
    element: <SharedEncounterView />,
  },
  {
    path: "/dev",
    element: <DevPage />,
  },
  {
    path: "/auth/callback",
    element: <AuthCallbackView />,
  },
  {
    path: "/review",
    element: <ReviewPage />,
  },
  {
    path: "/private",
    element: <PrivateAdversariesPage />,
  },
]);

