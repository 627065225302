import { Box, Button } from "@mui/material";
import { yellow } from "../core/muiTheme";

interface Props {
  close: () => void;
}

export function DialogCloseButton(props: Props) {
  return (
    <Box sx={{
      position: "absolute",
      bottom: '100%',
      right: '-2px',
      zIndex: 1,
      background: `linear-gradient(0deg, ${yellow[500]} 0%, ${yellow[300]} 80%, ${yellow[200]} 100%)`,
      pl: '2em',
      pr: '0.5em',
      pb: '0.1em',
      clipPath: 'polygon(2em 0%, 100% 0%, 100% 100%, 0% 100%)',
    }}>
      <Button
        onClick={props.close}
        sx={{
          color: '#000',
          borderRadius: 0,
          lineHeight: '0.8em',
          fontWeight: 700,
          fontSize: '1em',
        }}
      >Close</Button>
    </Box>
  );
} 