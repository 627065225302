import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { ShareEncounterDialog } from './ShareEncounterDialog';

export function ShareEncounterButton() {
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  return (
    <>
      <Tooltip title="Share Encounter" placement="top">
        <IconButton
          onClick={() => setShareDialogOpen(true)}
          color="primary"
        >
          <ShareIcon/>
        </IconButton>
      </Tooltip>
      <ShareEncounterDialog
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
      />
    </>
  );
} 