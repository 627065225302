import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useAdversaries } from '../../adversaries/useAdversaries';
import { AdversaryInput } from '../../generated/graphql';
import { convertAdversaryToFormData } from './HbAdversaryEditDialog';
import { isAdversary } from '../../adversaries/adversary.util';
import { AdversaryDetailsFragment } from '../../generated/graphql';

interface Props {
  open: boolean;
  onClose: () => void;
  onSelectTemplate: (template: AdversaryInput | null) => void;
}

export function HbAdversaryTemplateDialog(props: Props) {
  const { open, onClose, onSelectTemplate } = props;
  const { adversaries } = useAdversaries();
  const [searchQuery, setSearchQuery] = React.useState('');
  const [selectedAdversary, setSelectedAdversary] = React.useState<AdversaryInput | null>(null);

  // Filter out homebrew adversaries and environments
  const officialAdversaries = adversaries.filter(adv => 
    !adv.id && // Official adversaries don't have an ID
    isAdversary(adv) // This filters out environments
  ) as AdversaryDetailsFragment[];

  const filteredAdversaries = officialAdversaries.filter(adv =>
    adv.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleUseTemplate = () => {
    onSelectTemplate(selectedAdversary);
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Create New Adversary</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Choose a template to start from:
          </Typography>
          <TextField
            fullWidth
            size="small"
            placeholder="Search adversaries..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <List sx={{ maxHeight: 400, overflow: 'auto' }}>
          <ListItem disablePadding>
            <ListItemButton 
              onClick={() => setSelectedAdversary(null)}
              selected={selectedAdversary === null}
            >
              <ListItemText 
                primary="Empty Template" 
                secondary="Start from scratch"
              />
            </ListItemButton>
          </ListItem>
          {filteredAdversaries.map((adversary) => (
            <ListItem key={adversary.name} disablePadding>
              <ListItemButton
                onClick={() => setSelectedAdversary(convertAdversaryToFormData(adversary))}
                selected={selectedAdversary?.name === adversary.name}
              >
                <ListItemText
                  primary={adversary.name}
                  secondary={`${adversary.type} • Tier ${adversary.tier || '?'}`}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          variant="contained" 
          onClick={handleUseTemplate}
        >
          {selectedAdversary ? 'Use Template' : 'Use Empty Template'}
        </Button>
      </DialogActions>
    </Dialog>
  );
} 