import React from 'react';
import { useAdversaries } from './useAdversaries';
import { useDeck } from '../game-master/encounter/DeckBuilder/useDeck';
import { WelcomeText } from '../layout/WelcomeText';
import { AdversaryCardOverview } from './AdversaryCardOverview';
import HorizontalScroll from '../layout/HorizontalScroll';
import { BottomBar } from "../game-master/bottom-bar/BottomBar";
import { adversaryMainName } from "./Adversary/AdversaryName";
import { DeckCard } from "../game-master/encounter/DeckBuilder/Deck.types";
import { useAdversaryDiceRoll } from "./hooks/useAdversaryDiceRoll";
import {CardVerticalScroll} from "../layout/CardVerticalScroll";

export function AdversaryList() {
  const { adversaries, findAdversary } = useAdversaries();
  const { deck, updateCardInDeck, addDiceResultToCard } = useDeck();
  const { rollDice } = useAdversaryDiceRoll();

  if (!adversaries) {
    return <div>Loading...</div>;
  }

  if (deck.length === 0) {
    return <WelcomeText/>;
  }

  const onDiceRoll = async (card: DeckCard, dice: string) => {
    const name = adversaryMainName(card?.name || '', card?.instances ?? [])
    const result = await rollDice(dice, name);

    if (result) {
      addDiceResultToCard(card.id, result);
    }
  }

  return (
    <HorizontalScroll>
      {deck.map((card, index) => (
        <CardVerticalScroll key={card.id}>
          <AdversaryCardOverview
            key={index}
            elevation={2}
            adversary={findAdversary(card)!}
            card={card}
            onInstancesUpdate={(instances) => {
              updateCardInDeck(card.id, { ...card, instances });
            }}
            onDiceRoll={(result) => {
              onDiceRoll(card, result).then();
            }}
          />
        </CardVerticalScroll>
      ))}
      <BottomBar/>
    </HorizontalScroll>
  );
}
