import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box, Typography } from '@mui/material';
import LinkCopy from '../../../common/input/LinkCopy';
import { useEncounters } from '../../hooks/useEncounters';
import { serializeEncounter } from './encounterSerialization';

interface Props {
  open: boolean;
  onClose: () => void;
}

export function ShareEncounterDialog(props: Props) {
  const { currentEncounter } = useEncounters();
  const serializedEncounter = currentEncounter ? serializeEncounter(currentEncounter) : '';
  const shareLink = `${window.location.href}encounter?data=${serializedEncounter}`;

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm" PaperProps={{
      sx: {
        borderRadius: 2,
        borderWidth: 2,
        borderColor: 'warning.main',
        borderStyle: 'solid',
      },
    }}>
      <DialogContent>
        <Box textAlign="center">
          <Typography mb={2}>
            Share this link with other Game Masters! <br/>
            They can create their own copy of this encounter.
          </Typography>
          <LinkCopy link={shareLink}/>
          <Typography variant="body2" color="text.secondary" mt={2}>
            When they open this link, they'll see a preview of the encounter.
            They can then create their own copy to use and modify as needed.
            Any changes you make to this encounter won't affect their copy.<br />
            Any <em>Custom Adversaries</em> you've created will <strong>NOT</strong> be visible in the shared encounter.
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
} 