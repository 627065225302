import { EncounterState } from "../../types";

interface SerializedCard {
  n: string; // name
  a: string; // adversaryId
  q: number; // quantity
  u: number; // upscaling
  i: Array<{
    n?: string | null; // nameOverride
  }>;
}

interface SerializedEncounter {
  n: string; // name
  d: SerializedCard[]; // deck
}

export function serializeEncounter(encounter: EncounterState): string {
  // Create a minimal representation of the encounter
  const serializedEncounter: SerializedEncounter = {
    n: encounter.name,
    d: encounter.deck.map(card => ({
      n: card.name,
      a: card.adversaryId || '',
      q: card.quantity,
      u: card.upscaling,
      i: card.instances.map(instance => ({
        n: instance.nameOverride || null
      }))
    }))
  };

  // Convert to string and compress
  const jsonString = JSON.stringify(serializedEncounter);
  const compressed = btoa(jsonString); // Base64 encode to make it URL-safe and slightly more compact
  return encodeURIComponent(compressed);
}

export function deserializeEncounter(serialized: string): Omit<EncounterState, 'id' | 'updatedAt' | 'shouldStoreOnServer'> {
  try {
    const decoded = decodeURIComponent(serialized);
    const jsonString = atob(decoded); // Base64 decode
    const data: SerializedEncounter = JSON.parse(jsonString);
    
    return {
      name: data.n,
      deck: data.d.map((card, index) => ({
        id: index, // Generate new unique IDs for cards
        name: card.n,
        adversaryId: card.a || undefined,
        quantity: card.q,
        upscaling: card.u,
        instances: card.i.map(instance => ({
          nameOverride: instance.n || undefined,
          damageTaken: 0,
          stressTaken: 0,
          featuresUses: []
        }))
      }))
    };
  } catch (error) {
    console.error('Failed to deserialize encounter:', error);
    throw new Error('Invalid encounter data');
  }
} 