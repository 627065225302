import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import Dialog from "@mui/material/Dialog";
import { baseOffWhite, darkPurpleGradient, tealGradient, yellow } from "../../../core/muiTheme";
import React, { useState } from "react";
import { GmMainMenuTabs } from "./GmMainMenuTabs";
import { Box } from "@mui/material";
import { DarkMode } from "../../../core/muiThemeProviders";
import {DialogCloseButton} from "../../../common/DialogCloseButton";

export function GmMainMenuDialogButton() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen} startIcon={<MenuIcon/>}>
        Manage
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl" PaperProps={{
        sx: {
          // background: 'none',
          // boxShadow: 'none',
          // borderRadius: 0,
          borderRadius: 0,
          borderWidth: 2,
          borderColor: 'warning.main',
          borderStyle: 'solid',
          background: darkPurpleGradient,
          height: "calc(100% - 84px)",
          overflow: "visible",
        },
      }
      }>
        <DialogCloseButton close={handleClose}/>
        <DarkMode>
          <GmMainMenuTabs close={handleClose}/>
        </DarkMode>
      </Dialog>
    </div>
  );
}