import React, {useState} from 'react';
import {Box, Button} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import {deserializeEncounter} from '../game-master/encounter/DeckBuilder/encounterSerialization';
import {useEncounters} from '../game-master/hooks/useEncounters';
import {EncounterBackground} from '../game-master/encounter/EncounterBackground';
import {baseDarkBlue} from '../core/muiTheme';
import {AdversaryCard} from '../adversaries/AdversaryCard';
import {useAdversaries} from '../adversaries/useAdversaries';
import {CardInstance} from '../game-master/encounter/DeckBuilder/Deck.types';
import HorizontalScroll from "../layout/HorizontalScroll";
import {CardVerticalScroll} from "../layout/CardVerticalScroll";

export function SharedEncounterView() {
  const location = useLocation();
  const navigate = useNavigate();
  const {addEncounter, setCurrentEncounterId, updateCurrentDeck} = useEncounters();
  const {findAdversary} = useAdversaries();
  const [encounterData, setEncounterData] = useState<ReturnType<typeof deserializeEncounter> | null>(null);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const data = params.get('data');

    if (data) {
      try {
        const deserialized = deserializeEncounter(data);
        setEncounterData(deserialized);
      } catch (error) {
        console.error('Failed to deserialize encounter:', error);
      }
    }
  }, [location.search]);

  const handleUseEncounter = async () => {
    if (!encounterData) return;

    const id = addEncounter(encounterData.name, encounterData.deck);

    setCurrentEncounterId(id);
    navigate('/');
  };

  if (!encounterData) {
    return null;
  }

  return (
    <div className="App">
      <EncounterBackground
        background={baseDarkBlue}
        opacity={0.1}
      />

      <Box sx={{
        pt: 2,
        px: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}>
        <Box sx={{
          fontSize: '1.5em',
          fontWeight: 'bold',
          color: 'white',
        }}>
          {encounterData.name}
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUseEncounter}
        >
          Use Encounter
        </Button>
      </Box>
      <Box sx={{
        display: 'flex', flexGrow: 1, fontSize: `12px`, overflow: 'hidden',
      }}>
        <HorizontalScroll>
          {encounterData.deck.map(card => {
            const adversary = findAdversary(card);
            if (!adversary) return null;

            return (
              <CardVerticalScroll key={card.id}>
                <AdversaryCard
                  adversary={adversary}
                  card={card}
                  elevation={0}
                  onInstancesUpdate={(instances: CardInstance[]) => {
                    // No-op since this is a read-only view
                  }}
                />
              </CardVerticalScroll>
            );
          })}
        </HorizontalScroll>
      </Box>
    </div>
  );
} 