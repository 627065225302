import React from "react";
import {Box} from "@mui/material";

export function CardVerticalScroll(props: { children: React.ReactNode }) {
  return (
    <Box className="no-scrollbar" sx={{
      overflowY: 'auto',
      width: '26em',
      minWidth: '26em',
      // Need a little padding right for the outline
      p: '1.5em 2px 1.5em 1.5em',
      '&:last-child': {
        mr: '1.5em',
      },
    }}>
      <Box mb={8}> {/* So that the card can be scrolled above the navigation arrows and bottom bar */}
        {props.children}
      </Box>
    </Box>
  )
}