import React from 'react';
import { Box, Typography, Tooltip, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { usePlayerCount } from '../../hooks/usePlayerCount';
import { useDeck } from './useDeck';
import {AnyAdversary, useAdversaries} from '../../../adversaries/useAdversaries';
import {baseBlue, baseGreen, fcgOrange, fcgRed} from "../../../core/muiTheme";
import {Adversary as AdversaryHbOrOfficial} from "../../../adversaries/Adversary.type";
import {Environment} from "../../../adversaries/Environment.type";

function calculateBasePoints(playerCount: number): number {
  return 3 * playerCount + 2;
}

function calculateEncounterPoints(
  cards: {
    instanceCount: number;
    adversary: AnyAdversary | undefined,
  }[],
  playerCount: number
): number {
  let points = 0;
  let hasSpecialTypes = false;

  cards.forEach(card => {
    const adversary = card.adversary
    if (!adversary) return;

    const instances = card.instanceCount;

    // Not sure why, but there are some cases where the adversary type is not set for some older encounters
    const type: string | undefined = adversary.type?.toLowerCase();

    // Check for special types
    if (['horde', 'bruiser', 'leader', 'solo'].includes(type)) {
      hasSpecialTypes = true;
    }

    // Calculate points based on type
    switch (type) {
      case 'solo':
        points += 5 * instances;
        break;
      case 'leader':
        points += 3 * instances;
        break;
      case 'bruiser':
        points += 4 * instances;
        break;
      case 'minion':
        points += (1 / playerCount) * instances;
        break;
      case 'support':
        points += 1 * instances;
        break;
      default:
        points += 2 * instances;
    }
  });

  // Add 1 point if no special types
  if (!hasSpecialTypes) {
    points += 1;
  }

  return Math.round(points * 10) / 10; // Round to 1 decimal place
}

function getDifficultyText(encounterPoints: number, basePoints: number): { text: string; color: string } {
  const difference = encounterPoints - basePoints;
  
  if (difference <= -2) return { text: 'Very Easy', color: baseGreen };
  if (difference <= -1) return { text: 'Easy', color: baseGreen };
  if (difference < 1) return { text: 'Balanced', color: baseBlue };
  if (difference <= 2) return { text: 'Challenging', color: fcgOrange };
  return { text: 'Deadly', color: fcgRed };
}

export function EncounterDifficulty() {
  const { playerCount, increment, decrement } = usePlayerCount();
  const { deck } = useDeck();
  const { findAdversary } = useAdversaries();

  const basePoints = calculateBasePoints(playerCount);
  const cardData = deck.map(card => ({
    instanceCount: card.instances.length,
    adversary: findAdversary(card)
  }));

  const encounterPoints = calculateEncounterPoints(cardData, playerCount);
  const difficulty = getDifficultyText(encounterPoints, basePoints);

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'row',
      gap: 2.5,
      px: 1,
      py: 1,
      borderTop: '1px solid',
      borderColor: 'divider'
    }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography variant="caption" color="text.secondary"># of players</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconButton size="small" onClick={decrement}>
            <RemoveIcon fontSize="small" />
          </IconButton>
          <Typography>{playerCount}</Typography>
          <IconButton size="small" onClick={increment}>
            <AddIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography variant="caption" color="text.secondary">Difficulty</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <Typography
            sx={{
              fontWeight: 600,
              mt: 0.25,
            }}
          >
            <Tooltip title="Encounter Points" placement="top">
              <span>{encounterPoints}</span>
            </Tooltip>
            {' / '}
            <Tooltip title="3 x Players + 2" placement="top">
              <span>{basePoints}</span>
            </Tooltip>
            {' '}
            <Box component="span" sx={{color: difficulty.color}}>
              ({difficulty.text})
            </Box>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
} 