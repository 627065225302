import Dialog from "@mui/material/Dialog";
import { useCreateAdversaryMutation } from "../../generated/graphql";
import { HbAdversaryForm } from "./HbAdversaryForm";
import React from "react";
import ErrorMessage from "../../common/ErrorMessage";
import { defaultAdversaryFormData } from "./config/defaultAdversaryFormData";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { ModalInner } from "../../common/ModalInner";
import { HbAdversaryTemplateDialog } from "./HbAdversaryTemplateDialog";
import { AdversaryInput } from "../../generated/graphql";

interface Props {
  open: boolean;
  handleClose: () => void;
}

export function HbAdversaryCreateDialog(props: Props) {
  const [showTemplateDialog, setShowTemplateDialog] = React.useState(true);
  const [selectedTemplate, setSelectedTemplate] = React.useState<AdversaryInput | null>(null);

  // Reset template when dialog closes
  React.useEffect(() => {
    if (!props.open) {
      setShowTemplateDialog(true);
      setSelectedTemplate(null);
    }
  }, [props.open]);

  const handleTemplateSelect = (template: AdversaryInput | null) => {
    setSelectedTemplate(template);
    setShowTemplateDialog(false);
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalInner>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}>
          {showTemplateDialog ? (
            <HbAdversaryTemplateDialog
              open={showTemplateDialog}
              onClose={props.handleClose}
              onSelectTemplate={handleTemplateSelect}
            />
          ) : (
            <AdversaryCreateController
              onClose={props.handleClose}
              template={selectedTemplate}
            />
          )}
        </Box>
      </ModalInner>
    </Modal>
  );
}

function AdversaryCreateController(props: { 
  onClose: () => void;
  template: AdversaryInput | null;
}) {
  const [call, { error, loading }] = useCreateAdversaryMutation({
    refetchQueries: [
      'ListAdversaries',
      'ListMyAdversaries',
    ],
  });

  return (
    <>
      <ErrorMessage error={error} />
      <HbAdversaryForm
        isNew
        loading={loading}
        onClose={props.onClose}
        onSubmit={(adversary) => {
          call({
            variables: {
              input: adversary,
            }
          }).then(() => {
            props.onClose();
          });
        }}
        defaultData={props.template || defaultAdversaryFormData}
      />
    </>
  );
}


